import Head from "next/head";
import { Header } from "components/headers/Header";
import BackgroundGlassmorphism from "components/backgrounds/BackgroundGlassmorphism";
import Footer from "components/footer/Footer";
import FeaturedItemsSlider from "components/featured-items-slider/FeaturedItemsSlider";
import ExploreNearby from "components/explore-nearby/ExploreNearby";
import { FEATURED_REGIONS } from "data/featured-regions";
import Link from "next/link";

const PageNotFound = () => (
  <div className="relative overflow-hidden">
    <Head>
      <title>
        Sorry, we kunnen deze pagina niet meer vinden | TopVakantiehuizen.eu
      </title>
      <meta name="robots" content="noindex" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </Head>
    <Header />
    <BackgroundGlassmorphism />
    <main className="container relative mb-24 space-y-24 lg:mb-32 lg:space-y-32">
      <h1 className="text-3xl font-semibold md:text-5xl mt-14 mb-4">
        Sorry, we kunnen deze pagina niet meer vinden...
      </h1>
      <span className="text-base font-normal text-neutral-500 dark:text-neutral-400 sm:text-xl">
        We hebben ons best gedaan, maar het lijkt erop dat deze pagina niet
        (meer) bestaat of misschien verhuisd is. Je kunt natuurlijk altijd naar
        de{" "}
        <Link href="/" legacyBehavior>
          <a className="text-cyan-600">homepagina</a>
        </Link>{" "}
        gaan.
      </span>

      <FeaturedItemsSlider
        uniqueId="listing-nearby"
        countries={FEATURED_REGIONS}
        heading="Kijk gerust eens hier..."
        subHeading="De mooiste Nederlandse vakantiebestemmingen"
        categoryCardType="card5"
        itemPerRow={5}
      />

      <ExploreNearby />
    </main>
    <Footer />
  </div>
);

export default PageNotFound;
